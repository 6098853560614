import * as React from 'react'
import Layout from '../components/layout'

const IndexPage = () => {
  return (
    <Layout pageTitle="e404">
      
      <div className="text-red-500 font-poppins">
        <p className=''>There is nothing here :(</p>
        
      </div>
        
    </Layout>
  )
}

export default IndexPage
